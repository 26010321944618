import discord from './assets/discord.svg';
import twitter from './assets/twitter.svg';
import medium from './assets/medium.svg';
import logo from './assets/gf_logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <div>
          <img src={logo} className="social-logo" alt="logo" />
        </div>
        
        <h6>
          Changing the future of education by enabling students to work in roles that help them develop real skills. Get in touch to learn more.
        </h6>
        <p>
          <a href="mailto:team@gradientfoundation.com" >team@gradientfoundation.com</a>
        </p>
      </header>
    </div>
  );
}

export default App;
